<template>
    <ion-page>
        <move-header :title="headerTitle"></move-header>
        <ion-content>
            <slot></slot>
        </ion-content>
    </ion-page>
</template>
<script>
import { IonContent, IonPage } from '@ionic/vue';
import MoveHeader from './../../components/move-header.vue';

export default {
    name: 'move-layout-master',
    components:{
        MoveHeader,
        IonContent, 
        IonPage, 
    },
    props:{
        headerTitle: String
    }
}
</script>