import User from './../models/user';

class moveId {
    constructor(options) {
        this.axios = options.axios;
    }

    get client_id() {
        return "06f80795-c722-4e6f-a6e1-8cc8d5cffb7a";
    }

    get client_secret() {
        return "zvUBuWVLBsbeuB2qPvrrHD6C27ugUyJ9kt9grioykVjKe";
    }

    get api_url() {
        return 'https://www.moveid.be';
    }

    get api_prefix() {
        return '/api/v1/'
    }

    get axios() {
        return this._axios;
    }

    set axios(axios) {
        this._axios = axios;
    }
    loginByToken(token) {
        this.setToken(token);
        return this.getUserInfo().then(response => {
            this.setUser(response.data);
            return {
                success: true,
                user: this.getUser()
            };
        });
    }
    login(username, password) {
        return this.axios.post(this.api_url + '/oauth/token', this.getFormData({
            grant_type: 'password',
            client_id: this.client_id,
            client_secret: this.client_secret,
            username: username,
            password: password
        })).then(response => {
            return this.loginByToken(response.data);
        });
    }
    setToken(data) {
        let token = data;
        token.expire_time = Date.now() + (token.expires_in * 1000);
        localStorage.setItem('moveid_token', JSON.stringify(token));

        return token;
    }
    getToken() {
        const token = localStorage.getItem('moveid_token');
        if (!token) {
            return null;
        }

        return JSON.parse(token);
    }
    setUser(data) {
        localStorage.setItem('moveid_user', JSON.stringify(data));
    }
    getUser() {
        let user = JSON.parse(localStorage.getItem('moveid_user'));
        if (!user) {
            return null;
        }

        return new User(user);
    }
    logout() {
        return new Promise((resolve) => {
            localStorage.removeItem('moveid_user');
            localStorage.removeItem('moveid_token');

            resolve();
        });
    }
    sendResetPassword(email) {
        return this.axios.post(this.api_url + '/user/password?_format=json', {
            mail: email
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    refreshToken() {
        return this.formPost('/oauth/refresh', {
            grant_type: 'refresh_token',
            client_id: this.client_id,
            client_secret: this.client_secret,
            refresh_token: this.token.refresh_token
        }).then(response => {
            this.setToken(response.data);
        });
    }
    getUserInfo() {
        return this.get('user/me').then(userinfo => {
            return userinfo;
        });
    }
    checkMatch(activity) {
        return this.get('user/matches?activity=' + activity.id);
    }
    saveMatch(data) {
        if (data.id) {
            return this.patch('user/matches', data);
        } else {
            return this.post('user/matches', data);
        }
    }
    saveEvent(data) {
        if (data.id) {
            return this.patch('user/events', data);
        } else {
            return this.post('user/events', data);
        }
    }
    deleteEvent(data) {
        return this.delete('user/events', data);
    }
    /*getUserGroup(){
        return this.get('user/groups').then(response => {
            if(response.data && response.data[0]){
                return response.data[0];
            }
        });
    }*/
    getHeaders() {
        return {
            'Authorization': 'Bearer ' + this.getToken().access_token
        }
    }
    getPostHeaders() {
        return {
            ...this.getHeaders(),
            'Content-Type': 'application/json'
        }
    }
    get(url, options) {
        if (this.getToken()) {
            // Check if expires within minute
            if (this.getToken().expire_time <= Date.now() - 60000) {
                // refresh token
                return this.refreshToken().then(() => {
                    return this.axios.get(this.api_url + this.api_prefix + url, {
                        headers: this.getHeaders(),
                        params: options
                    });
                });
            } else {
                return this.axios.get(this.api_url + this.api_prefix + url, {
                    headers: this.getHeaders(),
                    params: options
                });
            }
        }

        return this.axios.get(this.api_url + this.api_prefix + url);
    }
    patch(url, data) {
        // Check if expires within minute
        if (this.getToken().expire_time <= Date.now() - 60000) {
            // refresh token
            return this.refreshToken().then(() => {
                return this.axios.patch(this.api_url + this.api_prefix + url, data, {
                    headers: this.getPostHeaders()
                });
            });
        } else {
            return this.axios.patch(this.api_url + this.api_prefix + url, data, {
                headers: this.getPostHeaders()
            });
        }
    }
    delete(url, data) {
        // Check if expires within minute
        if (this.getToken().expire_time <= Date.now() - 60000) {
            // refresh token
            return this.refreshToken().then(() => {
                return this.axios.delete(this.api_url + this.api_prefix + url, {
                    data: data,
                    headers: this.getPostHeaders()
                });
            });
        } else {
            return this.axios.delete(this.api_url + this.api_prefix + url, {
                data: data,
                headers: this.getPostHeaders()
            });
        }
    }
    post(url, data) {
        // Check if expires within minute
        if (this.getToken().expire_time <= Date.now() - 60000) {
            // refresh token
            return this.refreshToken().then(() => {
                return this.axios.post(this.api_url + this.api_prefix + url, data, {
                    headers: this.getPostHeaders()
                });
            });
        } else {
            return this.axios.post(this.api_url + this.api_prefix + url, data, {
                headers: this.getPostHeaders()
            });
        }
    }
    formPost(url, data) {
        if (this.getToken()) {
            // Check if expires within minute
            if (this.getToken().expire_time <= Date.now() - 60000) {
                // refresh token
                return this.refreshToken().then(() => {
                    return this.axios.post(this.api_url + url, this.getFormData(data));
                });
            } else {
                return this.axios.post(this.api_url + url, this.getFormData(data), {
                    headers: this.getHeaders()
                });
            }
        }

        return this.axios.post(this.api_url + url, this.getFormData(data));
    }
    getFormData(json) {
        var data = new FormData();
        for (const key in json) {
            if (Object.hasOwnProperty.call(json, key)) {
                const value = json[key];
                data.append(key, value);
            }
        }

        return data;
    }
    url(path) {
        return this.api_url + path;
    }
}

export default {
    install: (app, options) => {
        app.config.globalProperties.$moveid = new moveId(options);
    },

}