<template>
  <ion-header>
    <ion-toolbar color="primary" theme="dark">
      <ion-buttons v-if="$route.name != 'matching'" slot="start">
        <ion-back-button text="Terug"></ion-back-button>
      </ion-buttons>
      
      <ion-title>
        <div class="d-flex align-items-center">
        <img :src="require('./../assets/img/logo.svg')" style="width:40px;margin-right:1rem;" />{{title}}</div>
      </ion-title>

      <ion-buttons slot="end">
        <ion-button @click="openMessagesPopover($event)">
          <ion-icon :icon="notificationsOutline"></ion-icon>
          <ion-badge v-if="notifications.length > 0" color="light" slot="end">{{ notifications.length }}</ion-badge>
        </ion-button>
        <ion-popover :is-open="messagesPopoverOpen" :event="event" @didDismiss="messagesPopoverOpen = false" :dismiss-on-select="true">
          <ion-content>
      <ion-list class="pb-0">
        <ion-item v-for="notification in notifications" :key="notification.uuid" lines="none" :detail="notification.metadata?true:false" @click="notificationAction(notification)">
          <ion-icon slot="start" :icon="chatbubbleOutline"></ion-icon>
          <ion-label>
            <h3>{{ notification.title }}</h3>
            <div style="font-size:.8em" v-if="notification.body" v-html="notification.body"></div>
          </ion-label>
          
        </ion-item>
        <ion-item v-if="notifications.length == 0" lines="none">
          U heeft geen ongelezen berichten!
        </ion-item>
        <ion-item v-if="updateExists" @click="refreshApp" :button="true" :detail="false" lines="none">
          Update beschikbaar!
        </ion-item>
        <div class="px-2 py-1 text-right" style="background-color:#dddddd;font-size:0.8em;">v. {{version}}</div>
        </ion-list>
        </ion-content>
        </ion-popover>
        <ion-button @click="openPopover($event)">
          <ion-avatar slot="icon-only" style="max-width:36px;max-height:36px;">
              <img style="max-height:36px;max-width:36px;" :src="user.getPicture()" />
          </ion-avatar>
        </ion-button>
        <ion-popover :is-open="popoverOpen" :event="event" @didDismiss="popoverOpen = false" :dismiss-on-select="true">
          <ion-content>
      <ion-list>
        <ion-item :button="true" :detail="false" lines="none" @click="logout">Afmelden</ion-item>
        </ion-list>
        </ion-content>
        </ion-popover>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>
<script>
import {notificationsOutline,chatbubbleOutline} from 'ionicons/icons';
import { IonButtons, IonButton, IonTitle, IonToolbar, IonBackButton, IonAvatar, IonHeader, IonPopover, IonList, IonItem, IonContent, IonIcon, IonLabel, IonBadge} from '@ionic/vue';
import update from '/src/mixins/update.js'

export default {
    name:'move-header',
    components: {
      IonHeader, 
      IonButtons, 
      IonButton, 
      IonTitle, 
      IonToolbar,
      IonBackButton,
      IonAvatar,
      IonPopover, IonList, IonItem, IonContent, IonIcon, IonLabel, IonBadge
    },
    mixins: [update],
    props:{
      title: String
    },
    setup(){
      return {notificationsOutline, chatbubbleOutline};
    },
    data(){
      return {
        user: this.$moveid.getUser(),
        popoverOpen: false,
        messagesPopoverOpen: false,
        event: null,
        version: process.env.VUE_APP_VERSION,
        notifications: [],
        notificationsInterval: null
      }
    },
    beforeRouteLeave(){
      clearInterval(this.notificationsInterval);
    },
    mounted(){
      this.checkNewNotifications();

      clearInterval(this.notificationsInterval);
      setInterval(() => {
        this.checkNewNotifications();
      }, 10000);
    },
    methods:{
      openPopover(e) {
        this.event = e;
        this.popoverOpen = true;
      },
      openMessagesPopover(e) {
        this.event = e;
        this.messagesPopoverOpen = true;

        // Set notifications as read
        /*if(this.notifications.length > 0){
          const ids = [];
          for (let i = 0; i < this.notifications.length; i++) {
            const notification = this.notifications[i];
            ids.push(notification.uuid);
          }

          this.$moveid.patch('user/notifications', {
              ids: ids
            });
        }*/
        
      },
      notificationAction(notification){
        if(notification.metadata){
          if(notification.metadata.action == 'gotoRoute'){
            this.$router.push(notification.metadata.route);
          }
        }

        this.$moveid.patch('user/notifications', {
              ids: [notification.uuid]
        }).then(() => {
          this.checkNewNotifications();
        });
      },
      checkNewNotifications(){
        this.$moveid.get('user/notifications')
            .then(response => {
                this.notifications = response.data;
            });
      },
      logout(){
        this.$moveid.logout().then(() => {
          window.location.reload();
        });
      }
    }
}
</script>