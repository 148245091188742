<template>
  <ion-page>
      <ion-tabs>
        <ion-router-outlet></ion-router-outlet>
        <ion-tab-bar slot="bottom">
          <ion-tab-button tab="matching" href="/matching">
            <ion-icon :icon="home" />
            <ion-label>Start</ion-label>
          </ion-tab-button>
          <ion-tab-button tab="list" href="/activities">
            <ion-icon :icon="football" />
            <ion-label>Activiteiten</ion-label>
          </ion-tab-button>
          <ion-tab-button tab="together" href="/together">
            <ion-icon :icon="people" />
            <ion-label>Samen</ion-label>
          </ion-tab-button>
          <ion-tab-button tab="help" href="/help">
            <ion-icon :icon="bulb" />
            <ion-label>Hulp</ion-label>
          </ion-tab-button>
        </ion-tab-bar>
      </ion-tabs>
    </ion-page>
</template>
<script>
import {
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonLabel,
    IonIcon,
    IonPage,
    IonRouterOutlet
  } from '@ionic/vue';
  import { home, football, people, bulb } from 'ionicons/icons';

  export default {
    name: 'move-tabs',
    components: {
      IonLabel,
      IonTabs,
      IonTabBar,
      IonTabButton,
      IonIcon,
      IonPage,
      IonRouterOutlet,
    },
    setup() {
      return {
        home,
        football,
        people,
        bulb,
      };
    },
  };
</script>