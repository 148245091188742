import {sadOutline, happyOutline} from 'ionicons/icons';

class Settings{
    get ratings(){
        return [
            {
                value: 1,
                icon: sadOutline,
                color: '#cc0e0e'
            },
            {
                value: 2,
                icon: sadOutline,
                color: '#cc7d0e'
            },
            {
                value: 3,
                icon: happyOutline,
                color: '#889103'
            },
            {
                value: 4,
                icon: happyOutline,
                color: '#b0cc0e'
            },
            {
                value: 5,
                icon: happyOutline,
                color: '#37cc0e'
            }
        ];
    }
}

export default {
    install: (app, options) => {
        app.config.globalProperties.$settings = new Settings(options);
    },
    
}