import { createApp } from 'vue'
import './registerServiceWorker';
import App from './App.vue'
import router from './router';
import moveid from './plugins/moveid';
import settings from './plugins/settings';
import axios from 'axios';
import MoveMasterLayout from './views/layouts/Master.vue';
import MoveTabsTogether from './components/move-tabs-together.vue';
import 'v-calendar/dist/style.css';
import VCalendar from 'v-calendar';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

router.beforeEach((to, from, next) => {
  if (!to.matched.some(record => record.meta.guest)) {

    const user = localStorage.getItem('moveid_user');
    let token = localStorage.getItem('moveid_token');

    if (user && token) {
      token = JSON.parse(token);
      if (token.expire_time >= Date.now() - 6000) {
        next();
        return false;
      }
    }

    next({
      path: '/login',
      query: {
        redirect: to.fullPath
      }
    })
  } else {
    next() // make sure to always call next()!
  }
})

const app = createApp(App)
  .use(IonicVue)
  .use(moveid, {axios})
  .use(settings)
  .use(router);

app.use(VCalendar, {});
app.component('move-layout-master', MoveMasterLayout);
app.component('move-tabs-together', MoveTabsTogether);

router.isReady().then(() => {
  app.mount('#app');
});