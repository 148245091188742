export default class User {
    constructor(data){
        Object.assign(this, data);
    }
    getUsername(){
        return this.name;
    }
    getBuddy(){
        return new User(this.buddy);
    }
    getPicture(){
        if(this.picture){
            return this.picture;
        }

        // Change this to avatar icon
        return require('./../assets/img/avatar.png');
    }
}