<template>
  <ion-toolbar style="border-bottom:1px solid #DDDDDD;">
    <ion-buttons>
        <a href="/together/board" class="moveid-tab-link" :class="$route.name == 'board'?'moveid-tab-link-active':''">
          <ion-icon :icon="pinOutline"></ion-icon>
          <ion-label>Prikbord</ion-label>
        </a>

        <a href="/together/calendar" class="moveid-tab-link" :class="$route.name == 'calendar'?'moveid-tab-link-active':''">
          <ion-icon :icon="calendar"></ion-icon>
          <ion-label>Planning</ion-label>
        </a>

        <a href="/together/buddy" class="moveid-tab-link" :class="$route.name == 'buddy'?'moveid-tab-link-active':''">
          <ion-icon :icon="personCircleOutline"></ion-icon>
          <ion-label>Buddy</ion-label>
        </a>
  
        <a href="/together/chat" class="moveid-tab-link" :class="$route.name == 'chat'?'moveid-tab-link-active':''">
          <ion-icon :icon="chatbubblesOutline"></ion-icon>
          <ion-label>Chat</ion-label>
        </a>
    </ion-buttons>
  </ion-toolbar>
</template>
<script>
import {
    IonLabel,
    IonIcon,
    IonToolbar,
    IonButtons,
  } from '@ionic/vue';
  import { calendar, chatbubblesOutline, personCircleOutline, pinOutline } from 'ionicons/icons';

  export default {
    name: 'move-tabs',
    components: {
      IonLabel,
      IonIcon,
      IonToolbar,
      IonButtons,
    },
    setup() {
      return {
        calendar,
        chatbubblesOutline,
        pinOutline,
        personCircleOutline
      };
    },
  };
</script>