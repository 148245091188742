import { createRouter, createWebHistory } from '@ionic/vue-router';
import MoveTabs from '@/components/move-tabs.vue';

const routes = [
  {
    path: '/',
    component: MoveTabs,
    redirect: {
      name: 'matching'
    },
    children:[
      {
        path: '/matching',
        name: 'matching',
        component: () => import('@/views/Matching.vue'),
      }, {
        path: '/activities',
        component: () => import('@/views/Activities.vue'),
      },
      {
        path: '/activity/:id',
        name: 'activity',
        component: () => import('@/views/Activity.vue'),
      },
      {
        path: '/help',
        name: 'help',
        component: () => import('@/views/Help.vue'),
      },
      {
        path: 'together',
        name: 'together',
        redirect: {name:'board'}
      },
      {
        path: 'together/board',
        name: 'board',
        component: () => import('@/views/Board.vue'),
      },
      {
        path: 'together/calendar',
        name: 'calendar',
        component: () => import('@/views/Calendar.vue'),
      },
      {
        path: 'together/buddy',
        name: 'buddy',
        component: () => import('@/views/Buddy.vue'),
      },
      {
        path: '/together/chat',
        name: 'chat',
        component: () => import('@/views/Chat.vue'),
      },
      {
        path: 'together/calendar/add/activities',
        name: 'calendar.add.activities',
        component: () => import('@/views/AddEventActivities.vue'),
      },
      {
        path: 'together/calendar/edit/:id',
        name: 'calendar.edit',
        component: () => import('@/views/AddEvent.vue'),
      },
      {
        path: 'together/calendar/add/:activityId?',
        name: 'calendar.add',
        component: () => import('@/views/AddEvent.vue'),
      },
      {
        path: 'together/events/:id',
        name: 'event',
        component: () => import('@/views/Event.vue'),
      }
      
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      guest: true
    },
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    meta: {
      guest: true
    },
    component: () => import('@/views/ForgotPassword.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
